<template>
  <main class="support">
    <div>
      <LandingHeader />
      <div class="support__content">
        <ContactUsContent @close="closeContactDialog" />
      </div>
    </div>
    <LandingFooter />
    <img v-if="showArrowUp" alt="" class="arrow-up" src="@/assets/landing/arrow-up.svg" @click="scrollToTop" />
  </main>
</template>

<script>
import ContactUsContent from "./ContactUsContent";
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";

export default {
  name: "Support",
  components: {
    ContactUsContent,
    LandingHeader,
    LandingFooter,
  },
  data() {
    return {
      flickityTreatOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        autoPlay: true,
      },
      flickityFeedbackOptions: {
        prevNextButtons: false,
        wrapAround: true,
      },
      faqItems: [
        {
          question: this.$t("landing.patients.faq.question1"),
          answer: this.$t("landing.patients.faq.answer1"),
        },
        {
          question: this.$t("landing.patients.faq.question2"),
          answer: this.$t("landing.patients.faq.answer2"),
        },
        {
          question: this.$t("landing.patients.faq.question3"),
          answer: this.$t("landing.patients.faq.answer3"),
        },
        {
          question: this.$t("landing.patients.faq.question4"),
          answer: this.$t("landing.patients.faq.answer4"),
        },
        {
          question: this.$t("landing.patients.faq.question5"),
          answer: this.$t("landing.patients.faq.answer5"),
        },
        {
          question: this.$t("landing.patients.faq.question6"),
          answer: this.$t("landing.patients.faq.answer6"),
        },
        {
          question: this.$t("landing.patients.faq.question7"),
          answer: this.$t("landing.patients.faq.answer7"),
        },
        {
          question: this.$t("landing.patients.faq.question8"),
          answer: this.$t("landing.patients.faq.answer8"),
        },
        {
          question: this.$t("landing.patients.faq.question9"),
          answer: this.$t("landing.patients.faq.answer9"),
        },
      ],
      contactDialog: false,
      showArrowUp: false,
    };
  },
  methods: {
    nextTreatSlide() {
      this.$refs.flickityTreat1.next();
      this.$refs.flickityTreat2.next();
    },
    previousTreatSlide() {
      this.$refs.flickityTreat1.previous();
      this.$refs.flickityTreat2.previous();
    },
    nextFeedbackSlide() {
      this.$refs.flickityFeedback.next();
    },
    previousFeedbackSlide() {
      this.$refs.flickityFeedback.previous();
    },
    openContactDialog() {
      this.contactDialog = true;
    },
    closeContactDialog() {
      this.contactDialog = false;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "auto" });
    },
    onScroll() {
      this.showArrowUp = window.scrollY > 300;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "auto" });
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.support {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  * {
    font-size: 18px;
  }

  .contact-dialog__title {
    font-size: 44px;
    line-height: 48px;
    letter-spacing: -1px;
    margin-bottom: 40px;
  }

  &__content {
    max-width: $tablet-width;
    padding: 0 10px;
  }

  .arrow-up {
    position: fixed;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0.5;
    z-index: 9;

    @media (max-width: 768px) {
      width: 50px;
      height: 50px;
    }

    &:hover {
      opacity: 1;
    }
  }
}
</style>
